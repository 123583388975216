import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMonthlyMenu(ctx, queryParams) {
      console.log("fetchCategories");
      return new Promise((resolve, reject) => {
        axios
          .get('/hr/admin/monthlyfoodmenu', { params: queryParams })
          .then((response)=> { 
           

            return resolve(response)})
          .catch(error => reject(error))
      })
    },
    fetchTodayMenu(ctx, queryParams) {
      console.log("fetchCategories");
      return new Promise((resolve, reject) => {
        axios
          .get('/hr/admin/todaysmenu', { params: queryParams })
          .then((response)=> { 
           

            return resolve(response)})
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/hr/user/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadMenu(ctx, userData) {
     console.log(userData);
      return new Promise((resolve, reject) => {
        axios
          .post('/hr/admin/uploadmenu',  userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
