<template>

    <div>
  
      
  
      <TodayMenu/>
     
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
  
        <div class="m-2">
  
          <!-- Table Top -->
          <b-row>
  
            <!-- Per Page -->
            <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>

            <b-col    md="4">

            <b-button-toolbar
    key-nav
    aria-label="Toolbar with button groups"
    class="demo-inline-spacing"
  >

    <!-- group  1 -->
    <b-button-group>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-icon"
        variant="outline-primary"
        @click="decreaseMonth"
      >
        <feather-icon icon="ChevronsLeftIcon" />
      </b-button>
     
    </b-button-group>

    <!-- group 2 -->
    <b-button-group>
  
    <span>{{     getMonthName(currentMonth) }}</span>
      <span class="ml-1">{{   
    currentYear }}</span>
    </b-button-group>

    <!-- group 3 -->
    <b-button-group>
      
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-icon"
        variant="outline-primary"
        @click="increaseMonth"
      >
        <feather-icon icon="ChevronsRightIcon" />
      </b-button>
      
    </b-button-group>
  </b-button-toolbar>
             
            </b-col>
  
            <!-- Search -->
            <b-col
              cols="12"
              md="4"
            >
            <div class="d-flex align-items-center justify-content-end">
                
      <b-button
                  variant="primary"
                  to="hr-menu-list"
                  class="mr-2"
                >
                  <span class="text-nowrap">Upload Menu</span>
                </b-button>
                <b-button
                  variant="primary"
                  v-if="1>2"
                  @click="newMenuItem"
                >
                  <span class="text-nowrap">Add Menu Item</span>
                </b-button>
    
             
              </div>
            </b-col>
          </b-row>
  
        </div>
  
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchMonthlyMenu"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
  
          <!-- Column: User -->
          <template #cell(Date)="data">
            <b-media vertical-align="center">
              
               
                {{ resolveDate(data.item) }}  
             
              
            </b-media>
          </template>
  
          <!-- Column: User -->
          <template #cell(Menu)="data">
            <b-media vertical-align="center" class=" mt-1"  v-for="menuitem in   resolveMenuList(data.item)    " v-bind:key="menuitem.meal">
              <b-list-group-item class="d-flex  align-items-center">
      <span> {{ menuitem.menuItem }} </span>
      <b-badge
        variant="info"
        pill
        class="badge-round ml-1"
      >
        {{ menuitem.calories }}
      </b-badge>
    </b-list-group-item>
            
           
          
              
        
          </b-media>
               
                
             
           
          </template>
  
          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.upperCategory)}`"
              class="text-capitalize"
            >
          
            {{ data.item.upperName===null?  resolveUserStatusVariantText(data.item.upperCategory) : data.item.upperName }}
           
            </b-badge>
          </template>
  
          <!-- Column: Actions -->
          <template #cell(actions)="data">
             <b-button
                  variant=".btn-flat-dark"
                  @click="changeCategory(data.item)"
                >
                Edit
               </b-button>
          </template>
  
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
  
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
  
              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
  
            </b-col>
  
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,BListGroup, BListGroupItem, 
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { avatarText } from '@core/utils/filter' 
  import useUsersList from './useMenuList'
  import userStoreModule from './dailyMenuStoreModule'
  import TodayMenu from './TodaysMenu.vue'
  
  export default {
    
    computed:{
        
  
    },
     data() {
      return {
        selectedCategory:null,// this.generalData.generalsettings,
         
      }
    },
      provide() {
          const categorydata = {}
  
          Object.defineProperty(categorydata, "selectedCategory", {
              enumerable: true,
              get: () => this.selectedCategory,
          })
  
          return {
            
              categorydata,
          }
      },
    methods:{
      newMenuItem()
      {},

      changeCategory(e)
      {
        this.selectedCategory=e;
      this.isAddNewUserSidebarActive=true;
      console.log(e);
      },
       newCategory()
      {
        this.selectedCategory={
          id:null,
          name:null,
          upperCategory:null
        };
      this.isAddNewUserSidebarActive=true;
      
      }
     
    },
    components: {
      BListGroup, BListGroupItem, 
      TodayMenu,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
  
      vSelect,
    },
    setup() {
      const USER_APP_STORE_MODULE_NAME = 'app-daily-menu'
  
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      })
  
      const isAddNewUserSidebarActive = ref(false)
       
  
      const roleOptions = [
        { label: 'Admin', value: 'admin' },
        { label: 'Author', value: 'author' },
        { label: 'Editor', value: 'editor' },
        { label: 'Maintainer', value: 'maintainer' },
        { label: 'Subscriber', value: 'subscriber' },
      ]
  
      const planOptions = [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ]
  
      const statusOptions = [
        { label: 'Pending', value: 'pending' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ]
  
      const {
        increaseMonth,
    decreaseMonth,
        getMonthName,
        currentMonth,
    currentYear,
        resolveDate,
    resolveMenuList,
        fetchMonthlyMenu,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
  
        // UI
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        resolveUserStatusVariantText,
        resolveUserRolesArray,
        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
      } = useUsersList()
  
      return {
        increaseMonth,
    decreaseMonth,
        getMonthName,
        currentMonth,
    currentYear,
        resolveDate,
    resolveMenuList,
        // Sidebar
        isAddNewUserSidebarActive,
  
        fetchMonthlyMenu,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
  
        // Filter
        avatarText,
  
        // UI
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        resolveUserStatusVariantText,
        resolveUserRolesArray,
  
        roleOptions,
        planOptions,
        statusOptions,
  
        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  